import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import HeaderMargin from '../components/HeaderMargin';
import Layout from '../components/layout';
import LearnMoreSmall from '../components/LearnMoreSmall';
import SEO from '../components/seo';
import StudyList from '../components/StudyList';
import SpinTheWheel from '../components/SpinTheWheel';
import QuoteBlock from '../components/QuoteBlock';
import Video from '../components/Video';
import WheelChoice from '../components/WheelChoice';

import { WheelIntro } from '../content/wheel';

export default () => (
  <Layout>
    <SEO title="Training Room" />
    <HeaderMargin />
    <Video src={'https://player.vimeo.com/video/427160436?title=0&byline=0&portrait=0'} title={'Big Brothers'}/>
    <QuoteBlock
      grayText="Dan Burrell's Wheel of"
      redText="Purpose"
    >
      <WheelIntro /> 
    </QuoteBlock>
    <SpinTheWheel />
    <LearnMoreSmall
      buttonClass={'important'}
      color={'#666686'}
      text={'Ready for the next level?'}
      isWheel
      handleClick={() => navigate('/class')}
    />
    <StudyList />
    <LearnMoreSmall
      handleClick={() => navigate('/class')}
      text={'Time to Study Your Purpose?'}
      isWheel
    />
  </Layout>
);
