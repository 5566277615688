import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { wheelSections } from '../content/wheel';

const OuterModal = styled.div`
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${props => props.display ? 1 : 0};
  z-index: ${props => props.display ? 2 : -1};
`;

const InnerModal = styled.div`
  opacity: inherit;
  position: relative;
  width: fit-content;
  height: fit-content;
  max-height: 100vh;
  max-width: 90vw;
  margin: 0 auto;
  background: white;
  padding: 30px;
  vertical-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  height: inherit;
  width: 185px;
  background-image: url(${props => props.src}), linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  background-size: cover;
  @media screen and (max-width: 768px) {
    height: 300px;
    width: 200px;
    margin: 20px auto;
  }
`;

const SubContainer = styled.div`
  padding: 0 0 0 20px;
`;

const Title = styled.h4`
  color: ${props => props.theme.colors.important};
  font-family: ${props => props.theme.fonts.title};
  font-size: 38px;
`;

const Text = styled.p`
  margin-top: 30px;
  max-width: 500px;
`;

const Button = styled.button`
  display: inline-block;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.third};
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-weight: 900;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  width: fit-content;
  margin-top: 45px;
  &:hover {
    cursor: pointer;
  }
`;

const Close = styled.p`
  position: absolute;
  top: 10px;
  font-size: 38px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export default ({ closeModal, display, image, index, option }) => (
  <OuterModal display={display}>
    <InnerModal>
      <Close onClick={() => closeModal()}>x</Close>
      <Container>
        <ImageContainer src={image}>
        </ImageContainer>
        <SubContainer>
          <Title>{option}</Title>
          <Text>
            {wheelSections[index]}
          </Text>
          <Button onClick={() => navigate(`/${option.toLowerCase().replace(' ', '-')}`)}>Study {option}</Button>
        </SubContainer>
      </Container>
    </InnerModal>
  </OuterModal>
);
