import React from 'react';
import styled, { keyframes } from 'styled-components';
import wheel from '../images/wheel-of-purpose.png';

const rotate = props => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const arrive = props => keyframes`
  0% {
    transform: rotate(${props.prevAngle - 720}deg);
    filter: blur(0);
  }
  50% {
    filter: blur(0.6px);
  }
  100% {
    transform: rotate(${props.angle}deg);
    filter: blur(0);
  }
`;

const Wheel = styled.img`
  max-width: 500px;
  max-height: 500px;
  width: 40vw;
  height: 40vw;
  display: block;
  animation: ${arrive} 2s forwards;
  @media screen and (max-width: 768px) {
    width: 65vw;
    height: 65vw;
    margin: 0;
    box-sizing: border-box;
  }
`;

export default ({ angle, prevAngle }) => (
  <Wheel src={wheel} angle={angle} prevAngle={prevAngle}/>
)
