import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import Button from './Button';
import Wheel from './Wheel';
import WheelChoice from './WheelChoice';

const Container = styled.div`
  background: #f6f6f9;
  display: flex;
  flex-direction: row;
  width: 100vw;
  padding: 100px 5vw;
  box-sizing: border-box;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 100px 0;
  }
`;

const WheelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;

const Triangle = styled.div`
  height: 0;
  width: 0;
  margin-bottom: 20px;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 80px solid ${props => props.theme.colors.important};
  @media screen and (max-width: 768px) {
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 40px solid ${props => props.theme.colors.important};
  }
`;

export default (props) => {
  const [angle, setAngle] = useState(0);
  const [prevAngle, setPrevAngle] = useState(0);

  const randomAngle = () => {
    const newAngle = Math.floor(Math.random() * 6) * 60;
    setPrevAngle(angle);
    return newAngle === angle ? newAngle + 60 : newAngle;
  }

  return (
    <Container id="the-wheel">
      <WheelContainer>
        <Triangle />
        <Wheel angle={angle} prevAngle={prevAngle}/>
        <Button
          handleClick={() => setAngle(randomAngle())}
          space
          text={'Spin The Wheel'}
        />
      </WheelContainer>
      <WheelChoice choice={angle / 60}/>
    </Container>
  )
}
