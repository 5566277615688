import React, { useState } from 'react';
import styled from 'styled-components';

import images from '../images/wheel-study/images';
import StudyModal from '../components/StudyModal';

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
`;

const Title = styled.h3`
  font-family: ${props => props.theme.fonts.title};
  font-size: 38px;
  margin: 0 auto;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    padding: 0 20px;
  }
`;

const StudyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 128px;
  width: 1186px;
  max-width: 100vw;
  margin: 60px auto 0;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 32px;
  }
`;

const StudyItem = styled.div`
  max-height: 630px;
  height: 58vw;
  max-width: 370px;
  width: 30vw;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 40vw;
    height: 77vw;
  }
`;

const StudyImage = styled.img`
  max-height: 550px;
  height: 49vw;
  max-width: 370px;
  width: 30vw;
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 40vw;
    height: 65vw;
  }
`;

const StudyText = styled.h4`
  color: ${props => props.theme.colors.important};
  font-family: ${props => props.theme.fonts.title};
  font-size: 52px;
  margin-top: 16px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    font-size: 36px;
    margin-top: 8px;
  }
  @media screen and (max-width: 568px) {
    font-size: 24px;
  }
`;

export default (props) => {
  const [display, setDisplay] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);

  const options = ['Relationships', 'Self-Knowledge', 'Professional Life', 'Play', 'Spirituality', 'Health'];

  const openModal = (index) => {
    setCurrentOption(index);
    setDisplay(true);
  }

  const closeModal = () => {
    setCurrentOption(null);
    setDisplay(false);
  }

  return (
    <Container>
      <Title>Click on an Area of Focus to Study Further</Title> 
      <StudyContainer>
        { options.map((opt, index) => (
        <StudyItem onClick={() => openModal(index)}>
          <StudyImage src={images[index]}/>
          <StudyText>{opt}</StudyText>
        </StudyItem>
      )) }
      </StudyContainer>
      <StudyModal
        closeModal={closeModal}
        display={display}
        image={images[currentOption]}
        option={options[currentOption]}
        index={currentOption}
      />
    </Container>
  )
};
